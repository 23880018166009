<template>
  <div class="bets-wrapper" :class="{ empty: isEmpty }">
    <div v-if="!isEmpty" class="bookmaker-bets">
      <ActiveBet
        v-for="item in extendedBets"
        :key="item.id"
        :bet="item"
        type="extended"
      />
      <div v-if="pending" class="skeletons">
        <StSkeletonLoader
          v-for="i in 3"
          :key="i"
          height="242px"
          width="100%"
          radius="var(--border-radius-100)"
          bg-color="var(--background-secondary)"
          :shimmer-opacity="0.28"
        />
      </div>
      <div ref="endOfTheList" />
    </div>
    <div v-else class="empty-list">
      <img src="./empty.png" alt="empty list" width="200" height="113" />
      <p>{{ t('bets.empty') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import ActiveBet from '../ActiveBet/ActiveBet.vue'
import type { FindBetParams } from '../../types'
import { useFindHistoryBets } from '../../composables/useFindHistoryBets'

interface Props {
  betType?: 'inGame' | 'processed'
}
const props = withDefaults(defineProps<Props>(), {
  betType: 'inGame',
})
const betTypesParams = computed<
  ('new' | 'accepted' | 'processed' | 'paused')[]
>(() => {
  switch (props.betType) {
    case 'inGame':
      return ['paused', 'accepted']
    case 'processed':
      return ['processed']
    default:
      return ['paused', 'accepted']
  }
})
const params = computed<FindBetParams>(() => ({
  status: betTypesParams.value,
}))

const { t } = useI18n()

const endOfTheList = ref<HTMLDivElement>()

const { canLoadMore, loadMoreBets, extendedBets, pending, isEmpty } =
  useFindHistoryBets(params)

useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      await loadMoreBets()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
  options: {
    rootMargin: '300px',
  },
})
</script>

<style scoped>
.bets-wrapper {
  display: flex;

  &.empty {
    margin: auto;
  }
}

.bookmaker-bets {
  position: relative;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;

    display: block;

    height: 240px;

    background-color: var(--background-primary);
    border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;

    mask-image: linear-gradient(180deg, #d9d9d9 0%, rgb(217 217 217 / 0%) 100%);
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: center;

  width: 100%;

  p {
    margin: 0;
    font: var(--mobile-text-content-regular);
    color: var(--text-secondary);
  }
}

.pending {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.skeletons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
}
</style>
